import React from 'react';

const Page404 = () => {
  return (
    <main>
      <div className='container'>
        <h1>Pagina nu exista</h1>
      </div>
    </main>
  );
};

export default Page404;
