// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
// import { connectFunctionsEmulator } from 'firebase/functions';
// import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
// import { connectDatabaseEmulator } from 'firebase/database';
import { getDatabase, ref } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase();

export const firestoreDb = getFirestore();

// const location = window.location;

// if (location.hostname === 'localhost') {
//   // Point to the RTDB emulator running on localhost.
//   connectDatabaseEmulator(db, '127.0.0.1', 9000);
// }

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const dbRef = ref(db);

// DEV purposes
// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectFirestoreEmulator(firestoreDb, 'localhost', 8080);
// connectDatabaseEmulator(db, 'localhost', 9000);
