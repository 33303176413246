import React from 'react';
import ProductForm from './ProductForm';

const NewProduct = () => {
  return (
    <section className='pt-5 pb-5'>
      <h1>New product form</h1>
      <ProductForm />
    </section>
  );
};

export default NewProduct;
